"use client"

import { useCallback } from 'react'
import Icon from '@components/shared/Icon'
import useWindowScroll from '@hooks/useWindowScroll'
import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import BREAKPOINTS from '@constants/breakpoints'

import styles from 'styles/ToTop.module.css'

const ToTop = () => {
	const onMobile = useMediaQueryBreakpoint(BREAKPOINTS.showHamburger)

	const [y] = useWindowScroll()
	const shouldShow = !onMobile && y > 550
	const goUp = useCallback(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}, [])

	return shouldShow ? (
		<div
			className={onMobile ? styles.mobile : styles.totop}
			onClick={goUp}
		>
			<Icon>keyboard_capslock</Icon>
		</div>
	) : null
}

export default ToTop
